<template>
    <div style="min-height: 91px">
        <div class="multiple-option">
            <el-checkbox-group v-model="data.value">
                <el-checkbox :label="index" v-for="(item, index) in data.option">
                    <el-input v-model="item.name" placeholder="请输入内容"></el-input>
                    <i class="el-icon-close" @click.stop="del(index)"></i>
                </el-checkbox>
            </el-checkbox-group>
        </div>
        <el-button type="primary" plain @click="addOption">+ 添加选项</el-button>
    </div>
</template>

<script>
    export default {
        name: "MultipleChoice",
        props: ['data'],
        data() {
            return {

            }
        },
        methods: {
            //添加选项
            addOption() {
                let option = {
                    name: ''
                }
                if (this.data.option.length < 6) {
                    this.data.option.push(option);
                } else {
                    this.$message({
                        message: '最多只能添加六个选项！',
                        type: 'warning'
                    });
                }
            },
            del(index) {
                this.data.option.splice(index, 1);
            }
        }
    }
</script>

<style scoped lang="scss">
    .multiple-option {
        min-height: 40px;
        margin-bottom: 10px;
    }
</style>