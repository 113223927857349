<template>
    <div class="judge-option">
        <el-radio-group v-model="data.value">
            <el-radio :label="index" v-for="(item, index) in data.option">
                <el-input v-model="item.name" placeholder="请输入内容" class="single-input"></el-input>
            </el-radio>
        </el-radio-group>
    </div>
</template>

<script>
    export default {
        name: "Judge",
        props: ['data'],
        data() {
            return {

            }
        }
     }
</script>

<style scoped>

</style>