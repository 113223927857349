<template>
    <div>
        <div class="fill-item" v-model="data.value" v-for="(item, index) in data.option">
            <span>{{index+1}}</span>
            <el-input v-model="item.name" placeholder="请输入内容" class="fill-input"></el-input>
            <i class="el-icon-close" @click="del(index)"></i>
        </div>
        <el-button type="primary" plain @click="addOption">+ 添加选项</el-button>
    </div>
</template>

<script>
    export default {
        name: "Fill",
        props: ['data'],
        data() {
            return {

            }
        },
        methods: {
            //添加选项
            addOption() {
                let option = {
                    name: ''
                }
                if (this.data.option.length < 4) {
                    this.data.option.push(option);
                } else {
                    this.$message({
                        message: '最多只能设置四个空！',
                        type: 'warning'
                    });
                }
            },
            del(index) {
                this.data.option.splice(index, 1);
            }
        }
    }
</script>

<style scoped lang="scss">
    .fill-item {
        display: flex;
        justify-content: start;
        align-items: center;
        margin-bottom: 10px;
        .fill-input {
            width: 300px;
            padding: 0 10px;
        }
    }
</style>